import img from "./images/img_0.jpg"

const instagramAnimacija750 = {
  v: "5.6.8",
  fr: 30,
  ip: 0,
  op: 129,
  w: 750,
  h: 751,
  nm: "Animacija Instagram JPG Sequence 750 (FINAL)",
  ddd: 0,
  assets: [{ id: "image_0", w: 1000, h: 3914, u: "", p: img, e: 0 }],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [375, -1114.584, 0],
              to: [0, 31.833, 0],
              ti: [0, -31.833, 0],
            },
            { t: 50, s: [375, -923.584, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [68, 68, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 129,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Pratite nas na Instagramu Outlines",
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 24,
              s: [0],
            },
            { t: 66, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.11, y: 1 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [60, 2261.084, 0],
              to: [0, -48.5, 0],
              ti: [0, 48.5, 0],
            },
            { t: 60, s: [60, 1970.084, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.13, 0.13, 0.13], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 22,
              s: [119, 119, 100],
            },
            { t: 80, s: [80, 80, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Evolution_(%)_In",
          np: 3,
          mn: "ADBE Slider Control",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Slider",
              mn: "ADBE Slider Control-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.1], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    t: 20,
                    s: [705],
                  },
                  { t: 38.0009765625, s: [0] },
                ],
                ix: 1,
              },
            },
          ],
        },
        {
          ty: 5,
          nm: "Delay_In",
          np: 3,
          mn: "ADBE Slider Control",
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Slider",
              mn: "ADBE Slider Control-0001",
              ix: 1,
              v: { a: 0, k: 2, ix: 1 },
            },
          ],
        },
        {
          ty: 5,
          nm: "Reverse_In",
          np: 3,
          mn: "ADBE Checkbox Control",
          ix: 3,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: "Checkbox",
              mn: "ADBE Checkbox Control-0001",
              ix: 1,
              v: { a: 0, k: 0, ix: 1 },
            },
          ],
        },
        {
          ty: 5,
          nm: "Position_In",
          np: 3,
          mn: "ADBE Point3D Control",
          ix: 4,
          en: 1,
          ef: [
            {
              ty: 3,
              nm: "3D Point",
              mn: "ADBE Point3D Control-0001",
              ix: 1,
              v: { a: 0, k: [0, 3108.511, 0], ix: 1 },
            },
          ],
        },
        {
          ty: 5,
          nm: "Opacity_In",
          np: 3,
          mn: "ADBE Slider Control",
          ix: 5,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Slider",
              mn: "ADBE Slider Control-0001",
              ix: 1,
              v: { a: 0, k: 0, ix: 1 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.552, -0.576],
                        [-0.488, -1.55],
                        [0, -3.279],
                        [0, 0],
                        [0.486, -1.596],
                        [1.507, -0.532],
                        [3.013, -0.177],
                        [0, 0],
                        [-3.238, 0.046],
                        [-3.547, 0],
                        [-3.813, -0.044],
                        [-2.394, -0.177],
                        [0, 0],
                        [1.949, 0.532],
                        [0.709, 1.642],
                        [0, 3.281],
                        [0, 0],
                        [-1.197, 1.509],
                        [-3.813, 0],
                        [-3.015, -4.167],
                        [0, -8.423],
                        [1.108, -3.901],
                        [3.146, -2.394],
                        [5.939, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.701, 1.862],
                        [-2.749, 2.972],
                        [-1.11, 3.504],
                        [0, 3.192],
                        [5.586, 4.877],
                        [11.259, 0],
                        [3.857, -0.087],
                        [4.256, 0],
                        [3.236, 0.089],
                        [2.039, 0.089],
                      ],
                      o: [
                        [2.926, 0.089],
                        [1.55, 0.578],
                        [0.486, 1.552],
                        [0, 0],
                        [0, 3.192],
                        [-0.488, 1.596],
                        [-1.509, 0.532],
                        [0, 0],
                        [2.215, -0.177],
                        [3.236, -0.044],
                        [4.077, 0],
                        [3.811, 0.046],
                        [0, 0],
                        [-3.813, -0.087],
                        [-1.951, -0.532],
                        [-0.711, -1.64],
                        [0, 0],
                        [0, -4.609],
                        [1.197, -1.507],
                        [7.448, 0],
                        [3.013, 4.169],
                        [0, 4.522],
                        [-1.11, 3.903],
                        [-3.148, 2.394],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.269, 0],
                        [4.699, -1.862],
                        [2.747, -2.97],
                        [1.108, -3.502],
                        [0, -7.714],
                        [-5.586, -4.875],
                        [-1.596, 0],
                        [-3.857, 0.089],
                        [-3.192, 0],
                        [-3.238, -0.087],
                        [0, 0],
                      ],
                      v: [
                        [-408.237, -91.504],
                        [-401.521, -90.507],
                        [-398.462, -87.315],
                        [-397.73, -80.066],
                        [-397.73, -14.098],
                        [-398.462, -6.916],
                        [-401.454, -3.724],
                        [-408.237, -2.66],
                        [-408.237, 0],
                        [-400.058, -0.333],
                        [-389.883, -0.399],
                        [-378.046, -0.333],
                        [-368.736, 0],
                        [-368.736, -2.793],
                        [-377.381, -3.724],
                        [-381.371, -6.982],
                        [-382.435, -14.364],
                        [-382.435, -80.066],
                        [-380.64, -89.243],
                        [-373.125, -91.504],
                        [-357.431, -85.253],
                        [-352.909, -66.367],
                        [-354.572, -53.732],
                        [-360.956, -44.289],
                        [-374.588, -40.698],
                        [-386.292, -40.698],
                        [-386.292, -38.038],
                        [-373.258, -38.038],
                        [-355.303, -40.831],
                        [-344.131, -48.079],
                        [-338.346, -57.788],
                        [-336.683, -67.83],
                        [-345.062, -86.716],
                        [-370.332, -94.031],
                        [-378.512, -93.898],
                        [-390.681, -93.765],
                        [-400.324, -93.898],
                        [-408.237, -94.164],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "P",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "P",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.481, -1.55],
                        [1.773, -2.394],
                        [1.064, -2.571],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.192, 0.133],
                        [-3.192, 0],
                        [-3.77, -0.133],
                        [-2.041, -0.087],
                        [0, 0],
                        [2.394, 1.463],
                        [0, 4.345],
                        [0, 0],
                        [-1.419, 3.458],
                        [-2.44, 2.44],
                        [-3.192, 0.179],
                        [-0.887, -0.709],
                        [1.018, -1.374],
                        [0, -1.949],
                        [-1.552, -1.33],
                        [-2.217, 0],
                        [-1.552, 1.818],
                        [0, 2.573],
                        [0.931, 1.642],
                        [1.816, 1.064],
                        [2.571, 0],
                      ],
                      o: [
                        [-2.483, 1.552],
                        [-1.775, 2.394],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.683, -0.087],
                        [3.192, -0.133],
                        [4.167, 0],
                        [3.768, 0.133],
                        [0, 0],
                        [-4.256, 0],
                        [-2.394, -1.463],
                        [0, 0],
                        [0, -2.926],
                        [1.417, -3.458],
                        [2.438, -2.438],
                        [1.241, -0.087],
                        [-1.33, 0.798],
                        [-1.02, 1.376],
                        [0, 2.307],
                        [1.55, 1.33],
                        [2.571, 0],
                        [1.55, -1.816],
                        [0, -2.039],
                        [-0.931, -1.64],
                        [-1.818, -1.064],
                        [-3.015, 0],
                      ],
                      v: [
                        [-294.923, -68.163],
                        [-301.307, -62.244],
                        [-305.563, -54.796],
                        [-305.563, -70.091],
                        [-312.811, -68.695],
                        [-321.257, -68.229],
                        [-329.503, -68.628],
                        [-329.503, -65.835],
                        [-321.922, -62.976],
                        [-319.927, -54.131],
                        [-319.927, -12.369],
                        [-322.121, -4.788],
                        [-329.503, -2.793],
                        [-329.503, 0],
                        [-322.188, -0.333],
                        [-312.612, -0.532],
                        [-300.708, -0.333],
                        [-291.997, 0],
                        [-291.997, -2.793],
                        [-301.972, -4.988],
                        [-305.563, -13.699],
                        [-305.563, -44.289],
                        [-303.435, -53.865],
                        [-297.649, -62.71],
                        [-289.204, -66.633],
                        [-286.012, -65.702],
                        [-289.536, -62.444],
                        [-291.066, -57.456],
                        [-288.738, -52.003],
                        [-283.086, -50.008],
                        [-276.901, -52.735],
                        [-274.574, -59.318],
                        [-275.97, -64.838],
                        [-280.093, -68.894],
                        [-286.677, -70.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "r",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "r",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.504, 1.64],
                        [-2.573, 4.345],
                        [-1.552, -2.305],
                        [-4.877, 0],
                        [-1.908, 0.885],
                        [-1.862, 1.509],
                        [0, 0],
                        [0.885, -0.532],
                        [1.064, 0],
                        [0.532, 0.977],
                        [0, 2.128],
                        [0, 0],
                        [0.709, 3.059],
                        [2.215, 2.041],
                        [2.793, 0.844],
                        [3.811, 0],
                        [3.325, -0.975],
                        [2.128, -1.417],
                        [1.197, -1.507],
                        [0, -2.394],
                        [-1.33, -1.729],
                        [-2.749, 0],
                        [-1.642, 1.419],
                        [0, 2.483],
                        [1.284, 1.552],
                        [1.949, 0.532],
                        [-2.307, 0.578],
                        [-2.041, 0],
                        [-1.729, -0.798],
                        [-1.02, -1.906],
                        [0, -3.192],
                        [0, 0],
                        [1.816, -1.33],
                        [2.793, -1.018],
                        [3.146, -1.108],
                        [2.793, -1.55],
                        [1.773, -2.394],
                        [0, -3.811],
                        [-2.616, -2.97],
                        [-5.852, 0],
                      ],
                      o: [
                        [3.502, -1.64],
                        [0.087, 3.015],
                        [1.55, 2.307],
                        [2.926, 0],
                        [1.906, -0.885],
                        [0, 0],
                        [-0.887, 0.711],
                        [-0.887, 0.532],
                        [-1.419, 0],
                        [-0.532, -0.975],
                        [0, 0],
                        [0, -4.343],
                        [-0.711, -3.059],
                        [-1.862, -1.862],
                        [-2.793, -0.842],
                        [-4.079, 0],
                        [-3.325, 0.977],
                        [-1.951, 1.33],
                        [-1.197, 1.509],
                        [0, 2.307],
                        [1.33, 1.729],
                        [2.481, 0],
                        [1.64, -1.417],
                        [0, -2.305],
                        [-1.286, -1.55],
                        [1.33, -1.683],
                        [2.305, -0.576],
                        [2.394, 0],
                        [1.729, 0.798],
                        [1.018, 1.908],
                        [0, 0],
                        [0, 2.041],
                        [-1.818, 1.33],
                        [-2.793, 1.02],
                        [-3.148, 1.11],
                        [-2.793, 1.552],
                        [-1.775, 2.394],
                        [0, 4.611],
                        [2.614, 2.972],
                        [3.99, 0],
                      ],
                      v: [
                        [-240.327, -1.53],
                        [-231.216, -10.507],
                        [-228.756, -2.527],
                        [-219.113, 0.931],
                        [-211.865, -0.399],
                        [-206.212, -3.99],
                        [-207.542, -6.251],
                        [-210.202, -4.389],
                        [-213.128, -3.591],
                        [-216.054, -5.054],
                        [-216.852, -9.709],
                        [-216.852, -46.417],
                        [-217.916, -57.523],
                        [-222.305, -65.17],
                        [-229.288, -69.227],
                        [-239.196, -70.49],
                        [-250.302, -69.027],
                        [-258.481, -65.436],
                        [-263.203, -61.18],
                        [-264.998, -55.328],
                        [-263.003, -49.277],
                        [-256.885, -46.683],
                        [-250.701, -48.811],
                        [-248.24, -54.663],
                        [-250.169, -60.449],
                        [-255.023, -63.574],
                        [-249.57, -66.966],
                        [-243.053, -67.83],
                        [-236.869, -66.633],
                        [-232.746, -62.577],
                        [-231.216, -54.929],
                        [-231.216, -45.885],
                        [-233.943, -40.831],
                        [-240.859, -37.307],
                        [-249.77, -34.115],
                        [-258.681, -30.124],
                        [-265.53, -24.206],
                        [-268.19, -14.896],
                        [-264.267, -3.525],
                        [-251.565, 0.931],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, 1.862],
                        [0, 3.99],
                        [-1.862, 2.307],
                        [-2.616, 1.376],
                        [-2.527, 1.153],
                        [-1.243, 1.862],
                        [0, 0],
                        [2.215, -1.284],
                        [2.305, 0],
                      ],
                      o: [
                        [-1.862, -1.862],
                        [0, -4.077],
                        [1.862, -2.305],
                        [2.614, -1.374],
                        [2.527, -1.151],
                        [0, 0],
                        [-1.951, 3.105],
                        [-2.217, 1.286],
                        [-2.839, 0],
                      ],
                      v: [
                        [-251.299, -8.246],
                        [-254.092, -17.024],
                        [-251.299, -26.6],
                        [-244.583, -32.12],
                        [-236.869, -35.91],
                        [-231.216, -40.432],
                        [-231.216, -13.965],
                        [-237.467, -7.382],
                        [-244.25, -5.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.394, -0.399],
                        [2.747, -0.087],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.711, -2.837],
                        [-1.951, -2.039],
                        [-2.041, -0.752],
                        [-3.015, 0],
                        [-2.706, 3.236],
                        [-1.064, 4.701],
                        [0, 0],
                        [1.816, -1.862],
                        [2.305, 0],
                        [1.33, 1.509],
                        [0, 3.637],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-2.394, 0.399],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.701],
                        [0.709, 2.839],
                        [1.507, 1.419],
                        [2.039, 0.752],
                        [5.231, 0],
                        [2.704, -3.236],
                        [0, 0],
                        [-1.33, 4.435],
                        [-1.818, 1.862],
                        [-2.483, 0],
                        [-1.33, -1.507],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.041, 0.887],
                      ],
                      v: [
                        [-185.599, -88.645],
                        [-193.313, -87.913],
                        [-193.313, -68.495],
                        [-205.549, -68.495],
                        [-205.549, -65.835],
                        [-193.313, -65.835],
                        [-193.313, -21.147],
                        [-192.249, -9.842],
                        [-188.259, -2.527],
                        [-182.939, 0.732],
                        [-175.358, 1.862],
                        [-163.454, -2.993],
                        [-157.802, -14.896],
                        [-160.329, -15.827],
                        [-165.05, -6.384],
                        [-171.235, -3.591],
                        [-176.954, -5.852],
                        [-178.949, -13.566],
                        [-178.949, -65.835],
                        [-160.595, -65.835],
                        [-160.595, -68.495],
                        [-178.949, -68.495],
                        [-178.949, -90.573],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "t",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, -1.729],
                        [0, -2.571],
                        [-1.862, -1.729],
                        [-2.749, 0],
                        [-1.862, 1.729],
                        [0, 2.573],
                        [1.862, 1.729],
                        [2.747, 0],
                      ],
                      o: [
                        [-1.862, 1.729],
                        [0, 2.573],
                        [1.862, 1.729],
                        [2.747, 0],
                        [1.862, -1.729],
                        [0, -2.571],
                        [-1.862, -1.729],
                        [-2.749, 0],
                      ],
                      v: [
                        [-146.231, -99.551],
                        [-149.024, -93.1],
                        [-146.231, -86.65],
                        [-139.315, -84.056],
                        [-132.399, -86.65],
                        [-129.606, -93.1],
                        [-132.399, -99.551],
                        [-139.315, -102.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.281, 0.133],
                        [-3.105, 0],
                        [-3.148, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.463, 1.33],
                        [0, 3.724],
                        [0, 0],
                      ],
                      o: [
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.596, -0.087],
                        [3.279, -0.133],
                        [3.279, 0],
                        [3.146, 0.133],
                        [0, 0],
                        [-3.458, 0],
                        [-1.463, -1.33],
                        [0, 0],
                        [-2.217, 0.621],
                      ],
                      v: [
                        [-138.583, -68.695],
                        [-147.029, -68.229],
                        [-155.275, -68.628],
                        [-155.275, -65.835],
                        [-147.694, -62.976],
                        [-145.699, -54.131],
                        [-145.699, -12.369],
                        [-147.893, -4.788],
                        [-155.275, -2.793],
                        [-155.275, 0],
                        [-147.96, -0.333],
                        [-138.384, -0.532],
                        [-128.741, -0.333],
                        [-121.759, 0],
                        [-121.759, -2.793],
                        [-129.14, -4.788],
                        [-131.335, -12.369],
                        [-131.335, -70.091],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "i",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.394, -0.399],
                        [2.747, -0.087],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.711, -2.837],
                        [-1.951, -2.039],
                        [-2.041, -0.752],
                        [-3.015, 0],
                        [-2.706, 3.236],
                        [-1.064, 4.701],
                        [0, 0],
                        [1.816, -1.862],
                        [2.305, 0],
                        [1.33, 1.509],
                        [0, 3.637],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-2.394, 0.399],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.701],
                        [0.709, 2.839],
                        [1.507, 1.419],
                        [2.039, 0.752],
                        [5.231, 0],
                        [2.704, -3.236],
                        [0, 0],
                        [-1.33, 4.435],
                        [-1.818, 1.862],
                        [-2.483, 0],
                        [-1.33, -1.507],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.041, 0.887],
                      ],
                      v: [
                        [-98.752, -88.645],
                        [-106.466, -87.913],
                        [-106.466, -68.495],
                        [-118.702, -68.495],
                        [-118.702, -65.835],
                        [-106.466, -65.835],
                        [-106.466, -21.147],
                        [-105.402, -9.842],
                        [-101.412, -2.527],
                        [-96.092, 0.732],
                        [-88.511, 1.862],
                        [-76.607, -2.993],
                        [-70.955, -14.896],
                        [-73.482, -15.827],
                        [-78.203, -6.384],
                        [-84.388, -3.591],
                        [-90.107, -5.852],
                        [-92.102, -13.566],
                        [-92.102, -65.835],
                        [-73.748, -65.835],
                        [-73.748, -68.495],
                        [-92.102, -68.495],
                        [-92.102, -90.573],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "t 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.655, -2.97],
                        [2.571, -5.586],
                        [0, -7.891],
                        [-2.483, -5.231],
                        [-4.611, -2.793],
                        [-6.207, 0],
                        [-3.458, 1.816],
                        [-2.217, 2.926],
                        [-1.064, 3.015],
                        [0, 0],
                        [3.325, -1.773],
                        [3.545, 0],
                        [3.013, 2.084],
                        [1.862, 4.169],
                        [0, 6.118],
                        [-0.089, 1.463],
                        [-0.089, 1.243],
                        [0, 0],
                        [-2.793, 4.036],
                        [-3.813, 0],
                        [-1.685, -2.215],
                        [-0.798, -3.678],
                        [0.087, -4.343],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.433, 4.701],
                        [7.801, 0],
                      ],
                      o: [
                        [-4.655, 2.972],
                        [-2.573, 5.586],
                        [0, 7.182],
                        [2.481, 5.233],
                        [4.609, 2.793],
                        [4.609, 0],
                        [3.458, -1.816],
                        [2.215, -2.926],
                        [0, 0],
                        [-2.394, 4.788],
                        [-3.325, 1.775],
                        [-3.105, 0],
                        [-3.015, -2.082],
                        [-1.862, -4.167],
                        [0, -1.417],
                        [0.087, -1.463],
                        [0, 0],
                        [0.619, -8.865],
                        [2.793, -4.034],
                        [2.747, 0],
                        [1.683, 2.217],
                        [0.798, 3.68],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -10.195],
                        [-4.435, -4.699],
                        [-6.118, 0],
                      ],
                      v: [
                        [-51.735, -66.035],
                        [-62.575, -53.2],
                        [-66.432, -32.984],
                        [-62.708, -14.364],
                        [-52.068, -2.327],
                        [-35.842, 1.862],
                        [-23.739, -0.865],
                        [-15.227, -7.98],
                        [-10.306, -16.891],
                        [-12.966, -17.955],
                        [-21.544, -8.113],
                        [-31.852, -5.453],
                        [-41.029, -8.579],
                        [-48.344, -17.955],
                        [-51.137, -33.383],
                        [-51.004, -37.706],
                        [-50.738, -41.762],
                        [-51.137, -42.427],
                        [-46.016, -61.779],
                        [-36.108, -67.83],
                        [-29.458, -64.505],
                        [-25.734, -55.661],
                        [-24.67, -43.624],
                        [-56.723, -43.624],
                        [-56.457, -41.097],
                        [-10.572, -41.097],
                        [-17.222, -63.441],
                        [-35.576, -70.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "e",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.1, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 25,
                    s: [-209.271, 386.221],
                    to: [0, -72.727],
                    ti: [0, 72.727],
                  },
                  { t: 60, s: [-209.271, -50.141] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [-209.271, -50.141], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.034, -2.215],
                        [2.305, -5.497],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.281, 0.133],
                        [-3.015, 0],
                        [-2.882, -0.133],
                        [-1.419, -0.087],
                        [0, 0],
                        [1.284, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [-3.148, 4.079],
                        [-4.967, 0],
                        [-1.419, -1.284],
                        [-0.445, -2.082],
                        [0, -2.571],
                        [0, 0],
                        [1.241, -1.33],
                        [3.013, 0],
                        [0, 0],
                        [-2.972, 0.133],
                        [-3.015, 0],
                        [-3.192, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.463, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [0.665, 2.66],
                        [1.596, 1.685],
                        [2.261, 0.931],
                        [3.545, 0],
                      ],
                      o: [
                        [-4.036, 2.217],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.596, -0.087],
                        [3.279, -0.133],
                        [3.103, 0],
                        [2.88, 0.133],
                        [0, 0],
                        [-2.926, 0],
                        [-1.286, -1.33],
                        [0, 0],
                        [0, -6.029],
                        [3.146, -4.077],
                        [2.837, 0],
                        [1.417, 1.286],
                        [0.443, 2.084],
                        [0, 0],
                        [0, 3.724],
                        [-1.243, 1.33],
                        [0, 0],
                        [1.507, -0.087],
                        [2.97, -0.133],
                        [3.103, 0],
                        [3.192, 0.133],
                        [0, 0],
                        [-3.458, 0],
                        [-1.463, -1.33],
                        [0, 0],
                        [0, -4.522],
                        [-0.665, -2.66],
                        [-1.509, -1.596],
                        [-2.261, -0.931],
                        [-4.522, 0],
                      ],
                      v: [
                        [64.504, -67.165],
                        [54.994, -55.594],
                        [54.994, -70.091],
                        [47.746, -68.695],
                        [39.3, -68.229],
                        [31.054, -68.628],
                        [31.054, -65.835],
                        [38.635, -62.976],
                        [40.63, -54.131],
                        [40.63, -12.369],
                        [38.436, -4.788],
                        [31.054, -2.793],
                        [31.054, 0],
                        [38.369, -0.333],
                        [47.812, -0.532],
                        [56.79, -0.333],
                        [63.24, 0],
                        [63.24, -2.793],
                        [56.923, -4.788],
                        [54.994, -12.369],
                        [54.994, -44.422],
                        [59.716, -59.584],
                        [71.885, -65.702],
                        [78.269, -63.773],
                        [81.062, -58.719],
                        [81.727, -51.737],
                        [81.727, -12.369],
                        [79.865, -4.788],
                        [73.481, -2.793],
                        [73.481, 0],
                        [80.198, -0.333],
                        [89.175, -0.532],
                        [98.618, -0.333],
                        [105.667, 0],
                        [105.667, -2.793],
                        [98.286, -4.788],
                        [96.091, -12.369],
                        [96.091, -48.013],
                        [95.094, -58.786],
                        [91.702, -65.303],
                        [86.05, -69.094],
                        [77.338, -70.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "n",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "n",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.504, 1.64],
                        [-2.573, 4.345],
                        [-1.552, -2.305],
                        [-4.877, 0],
                        [-1.908, 0.885],
                        [-1.862, 1.509],
                        [0, 0],
                        [0.885, -0.532],
                        [1.064, 0],
                        [0.532, 0.977],
                        [0, 2.128],
                        [0, 0],
                        [0.709, 3.059],
                        [2.215, 2.041],
                        [2.793, 0.844],
                        [3.811, 0],
                        [3.325, -0.975],
                        [2.128, -1.417],
                        [1.197, -1.507],
                        [0, -2.394],
                        [-1.33, -1.729],
                        [-2.749, 0],
                        [-1.642, 1.419],
                        [0, 2.483],
                        [1.284, 1.552],
                        [1.949, 0.532],
                        [-2.307, 0.578],
                        [-2.041, 0],
                        [-1.729, -0.798],
                        [-1.02, -1.906],
                        [0, -3.192],
                        [0, 0],
                        [1.816, -1.33],
                        [2.793, -1.018],
                        [3.146, -1.108],
                        [2.793, -1.55],
                        [1.773, -2.394],
                        [0, -3.811],
                        [-2.616, -2.97],
                        [-5.852, 0],
                      ],
                      o: [
                        [3.502, -1.64],
                        [0.087, 3.015],
                        [1.55, 2.307],
                        [2.926, 0],
                        [1.906, -0.885],
                        [0, 0],
                        [-0.887, 0.711],
                        [-0.887, 0.532],
                        [-1.419, 0],
                        [-0.532, -0.975],
                        [0, 0],
                        [0, -4.343],
                        [-0.711, -3.059],
                        [-1.862, -1.862],
                        [-2.793, -0.842],
                        [-4.079, 0],
                        [-3.325, 0.977],
                        [-1.951, 1.33],
                        [-1.197, 1.509],
                        [0, 2.307],
                        [1.33, 1.729],
                        [2.481, 0],
                        [1.64, -1.417],
                        [0, -2.305],
                        [-1.286, -1.55],
                        [1.33, -1.683],
                        [2.305, -0.576],
                        [2.394, 0],
                        [1.729, 0.798],
                        [1.018, 1.908],
                        [0, 0],
                        [0, 2.041],
                        [-1.818, 1.33],
                        [-2.793, 1.02],
                        [-3.148, 1.11],
                        [-2.793, 1.552],
                        [-1.775, 2.394],
                        [0, 4.611],
                        [2.614, 2.972],
                        [3.99, 0],
                      ],
                      v: [
                        [140.313, -1.53],
                        [149.424, -10.507],
                        [151.884, -2.527],
                        [161.527, 0.931],
                        [168.775, -0.399],
                        [174.428, -3.99],
                        [173.098, -6.251],
                        [170.438, -4.389],
                        [167.512, -3.591],
                        [164.586, -5.054],
                        [163.788, -9.709],
                        [163.788, -46.417],
                        [162.724, -57.523],
                        [158.335, -65.17],
                        [151.352, -69.227],
                        [141.444, -70.49],
                        [130.338, -69.027],
                        [122.159, -65.436],
                        [117.437, -61.18],
                        [115.642, -55.328],
                        [117.637, -49.277],
                        [123.755, -46.683],
                        [129.939, -48.811],
                        [132.4, -54.663],
                        [130.471, -60.449],
                        [125.617, -63.574],
                        [131.07, -66.966],
                        [137.587, -67.83],
                        [143.771, -66.633],
                        [147.894, -62.577],
                        [149.424, -54.929],
                        [149.424, -45.885],
                        [146.697, -40.831],
                        [139.781, -37.307],
                        [130.87, -34.115],
                        [121.959, -30.124],
                        [115.11, -24.206],
                        [112.45, -14.896],
                        [116.373, -3.525],
                        [129.075, 0.931],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, 1.862],
                        [0, 3.99],
                        [-1.862, 2.307],
                        [-2.616, 1.376],
                        [-2.527, 1.153],
                        [-1.243, 1.862],
                        [0, 0],
                        [2.215, -1.284],
                        [2.305, 0],
                      ],
                      o: [
                        [-1.862, -1.862],
                        [0, -4.077],
                        [1.862, -2.305],
                        [2.614, -1.374],
                        [2.527, -1.151],
                        [0, 0],
                        [-1.951, 3.105],
                        [-2.217, 1.286],
                        [-2.839, 0],
                      ],
                      v: [
                        [129.341, -8.246],
                        [126.548, -17.024],
                        [129.341, -26.6],
                        [136.057, -32.12],
                        [143.771, -35.91],
                        [149.424, -40.432],
                        [149.424, -13.965],
                        [143.173, -7.382],
                        [136.39, -5.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.192, -1.284],
                        [1.906, -2.571],
                        [0, -3.99],
                        [-1.685, -2.614],
                        [-2.706, -2.082],
                        [-3.015, -2.128],
                        [-2.706, -2.527],
                        [0, -3.901],
                        [2.394, -1.729],
                        [2.481, 0],
                        [2.438, 2.084],
                        [1.241, 3.724],
                        [0.619, 4.877],
                        [0, 0],
                        [0.087, -4.077],
                        [0.177, -2.837],
                        [0, 0],
                        [-0.711, 0.798],
                        [-0.887, -0.619],
                        [-0.931, -0.576],
                        [-0.977, -0.532],
                        [-1.862, -0.399],
                        [-2.128, 0],
                        [-3.547, 1.463],
                        [-2.174, 2.839],
                        [0, 4.079],
                        [3.369, 3.371],
                        [4.875, 3.281],
                        [2.527, 1.685],
                        [1.596, 1.951],
                        [0, 2.66],
                        [-1.951, 1.642],
                        [-2.926, 0],
                        [-2.44, -2.039],
                        [-1.286, -3.103],
                        [-0.532, -3.279],
                        [0, 0],
                        [-0.089, 3.504],
                        [-0.179, 2.483],
                        [0, 0],
                        [2.926, 2.307],
                        [2.614, 1.11],
                        [3.545, 0],
                      ],
                      o: [
                        [-3.192, 1.286],
                        [-1.908, 2.573],
                        [0, 3.637],
                        [1.683, 2.616],
                        [2.704, 2.084],
                        [4.256, 2.839],
                        [2.704, 2.527],
                        [0, 4.256],
                        [-2.394, 1.729],
                        [-4.256, 0],
                        [-2.44, -2.082],
                        [-1.243, -3.724],
                        [0, 0],
                        [0, 6.739],
                        [-0.089, 4.079],
                        [0, 0],
                        [0.353, -2.039],
                        [0.709, -0.798],
                        [0.885, 0.532],
                        [0.931, 0.578],
                        [1.596, 0.619],
                        [1.862, 0.399],
                        [4.256, 0],
                        [3.545, -1.463],
                        [2.172, -2.837],
                        [0, -5.407],
                        [-3.371, -3.369],
                        [-2.926, -1.949],
                        [-2.527, -1.683],
                        [-1.596, -1.949],
                        [0, -3.192],
                        [1.949, -1.64],
                        [3.901, 0],
                        [2.438, 2.041],
                        [1.284, 3.105],
                        [0, 0],
                        [0, -5.763],
                        [0.087, -3.502],
                        [0, 0],
                        [-0.711, 5.32],
                        [-1.153, -0.885],
                        [-2.616, -1.108],
                        [-3.637, 0],
                      ],
                      v: [
                        [192.913, -68.562],
                        [185.266, -62.776],
                        [182.406, -52.934],
                        [184.933, -43.558],
                        [191.517, -36.508],
                        [200.095, -30.191],
                        [210.536, -22.145],
                        [214.592, -12.502],
                        [211.001, -3.525],
                        [203.686, -0.931],
                        [193.645, -4.057],
                        [188.125, -12.768],
                        [185.332, -25.669],
                        [182.406, -25.669],
                        [182.273, -9.443],
                        [181.874, 0.931],
                        [184.8, 0.931],
                        [186.396, -3.325],
                        [188.79, -3.591],
                        [191.517, -1.928],
                        [194.376, -0.266],
                        [199.563, 1.264],
                        [205.548, 1.862],
                        [217.252, -0.333],
                        [225.831, -6.783],
                        [229.089, -17.157],
                        [224.035, -30.324],
                        [211.666, -40.299],
                        [203.487, -45.752],
                        [197.302, -51.205],
                        [194.908, -58.121],
                        [197.834, -65.37],
                        [205.149, -67.83],
                        [214.659, -64.771],
                        [220.245, -57.057],
                        [222.971, -47.481],
                        [225.897, -47.481],
                        [226.03, -61.38],
                        [226.429, -70.357],
                        [223.503, -70.357],
                        [218.05, -65.835],
                        [212.398, -68.828],
                        [203.154, -70.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "s",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "s",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.1, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 27,
                    s: [130.072, 386.221],
                    to: [0, -70.089],
                    ti: [0, 70.089],
                  },
                  { t: 73, s: [130.072, -34.314] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [130.072, -34.314], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.034, -2.215],
                        [2.305, -5.497],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.281, 0.133],
                        [-3.015, 0],
                        [-2.882, -0.133],
                        [-1.419, -0.087],
                        [0, 0],
                        [1.284, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [-3.148, 4.079],
                        [-4.967, 0],
                        [-1.419, -1.284],
                        [-0.445, -2.082],
                        [0, -2.571],
                        [0, 0],
                        [1.241, -1.33],
                        [3.013, 0],
                        [0, 0],
                        [-2.972, 0.133],
                        [-3.015, 0],
                        [-3.192, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.463, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [0.665, 2.66],
                        [1.596, 1.685],
                        [2.261, 0.931],
                        [3.545, 0],
                      ],
                      o: [
                        [-4.036, 2.217],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.596, -0.087],
                        [3.279, -0.133],
                        [3.103, 0],
                        [2.88, 0.133],
                        [0, 0],
                        [-2.926, 0],
                        [-1.286, -1.33],
                        [0, 0],
                        [0, -6.029],
                        [3.146, -4.077],
                        [2.837, 0],
                        [1.417, 1.286],
                        [0.443, 2.084],
                        [0, 0],
                        [0, 3.724],
                        [-1.243, 1.33],
                        [0, 0],
                        [1.507, -0.087],
                        [2.97, -0.133],
                        [3.103, 0],
                        [3.192, 0.133],
                        [0, 0],
                        [-3.458, 0],
                        [-1.463, -1.33],
                        [0, 0],
                        [0, -4.522],
                        [-0.665, -2.66],
                        [-1.509, -1.596],
                        [-2.261, -0.931],
                        [-4.522, 0],
                      ],
                      v: [
                        [304.432, -67.165],
                        [294.922, -55.594],
                        [294.922, -70.091],
                        [287.674, -68.695],
                        [279.228, -68.229],
                        [270.982, -68.628],
                        [270.982, -65.835],
                        [278.563, -62.976],
                        [280.558, -54.131],
                        [280.558, -12.369],
                        [278.364, -4.788],
                        [270.982, -2.793],
                        [270.982, 0],
                        [278.297, -0.333],
                        [287.74, -0.532],
                        [296.718, -0.333],
                        [303.168, 0],
                        [303.168, -2.793],
                        [296.851, -4.788],
                        [294.922, -12.369],
                        [294.922, -44.422],
                        [299.644, -59.584],
                        [311.813, -65.702],
                        [318.197, -63.773],
                        [320.99, -58.719],
                        [321.655, -51.737],
                        [321.655, -12.369],
                        [319.793, -4.788],
                        [313.409, -2.793],
                        [313.409, 0],
                        [320.126, -0.333],
                        [329.103, -0.532],
                        [338.546, -0.333],
                        [345.595, 0],
                        [345.595, -2.793],
                        [338.214, -4.788],
                        [336.019, -12.369],
                        [336.019, -48.013],
                        [335.022, -58.786],
                        [331.63, -65.303],
                        [325.978, -69.094],
                        [317.266, -70.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "n",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "n",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.504, 1.64],
                        [-2.573, 4.345],
                        [-1.552, -2.305],
                        [-4.877, 0],
                        [-1.908, 0.885],
                        [-1.862, 1.509],
                        [0, 0],
                        [0.885, -0.532],
                        [1.064, 0],
                        [0.532, 0.977],
                        [0, 2.128],
                        [0, 0],
                        [0.709, 3.059],
                        [2.215, 2.041],
                        [2.793, 0.844],
                        [3.811, 0],
                        [3.325, -0.975],
                        [2.128, -1.417],
                        [1.197, -1.507],
                        [0, -2.394],
                        [-1.33, -1.729],
                        [-2.749, 0],
                        [-1.642, 1.419],
                        [0, 2.483],
                        [1.284, 1.552],
                        [1.949, 0.532],
                        [-2.307, 0.578],
                        [-2.041, 0],
                        [-1.729, -0.798],
                        [-1.02, -1.906],
                        [0, -3.192],
                        [0, 0],
                        [1.816, -1.33],
                        [2.793, -1.018],
                        [3.146, -1.108],
                        [2.793, -1.55],
                        [1.773, -2.394],
                        [0, -3.811],
                        [-2.616, -2.97],
                        [-5.852, 0],
                      ],
                      o: [
                        [3.502, -1.64],
                        [0.087, 3.015],
                        [1.55, 2.307],
                        [2.926, 0],
                        [1.906, -0.885],
                        [0, 0],
                        [-0.887, 0.711],
                        [-0.887, 0.532],
                        [-1.419, 0],
                        [-0.532, -0.975],
                        [0, 0],
                        [0, -4.343],
                        [-0.711, -3.059],
                        [-1.862, -1.862],
                        [-2.793, -0.842],
                        [-4.079, 0],
                        [-3.325, 0.977],
                        [-1.951, 1.33],
                        [-1.197, 1.509],
                        [0, 2.307],
                        [1.33, 1.729],
                        [2.481, 0],
                        [1.64, -1.417],
                        [0, -2.305],
                        [-1.286, -1.55],
                        [1.33, -1.683],
                        [2.305, -0.576],
                        [2.394, 0],
                        [1.729, 0.798],
                        [1.018, 1.908],
                        [0, 0],
                        [0, 2.041],
                        [-1.818, 1.33],
                        [-2.793, 1.02],
                        [-3.148, 1.11],
                        [-2.793, 1.552],
                        [-1.775, 2.394],
                        [0, 4.611],
                        [2.614, 2.972],
                        [3.99, 0],
                      ],
                      v: [
                        [380.241, -1.53],
                        [389.352, -10.507],
                        [391.812, -2.527],
                        [401.455, 0.931],
                        [408.703, -0.399],
                        [414.356, -3.99],
                        [413.026, -6.251],
                        [410.366, -4.389],
                        [407.44, -3.591],
                        [404.514, -5.054],
                        [403.716, -9.709],
                        [403.716, -46.417],
                        [402.652, -57.523],
                        [398.263, -65.17],
                        [391.28, -69.227],
                        [381.372, -70.49],
                        [370.266, -69.027],
                        [362.087, -65.436],
                        [357.365, -61.18],
                        [355.57, -55.328],
                        [357.565, -49.277],
                        [363.683, -46.683],
                        [369.867, -48.811],
                        [372.328, -54.663],
                        [370.399, -60.449],
                        [365.545, -63.574],
                        [370.998, -66.966],
                        [377.515, -67.83],
                        [383.699, -66.633],
                        [387.822, -62.577],
                        [389.352, -54.929],
                        [389.352, -45.885],
                        [386.625, -40.831],
                        [379.709, -37.307],
                        [370.798, -34.115],
                        [361.887, -30.124],
                        [355.038, -24.206],
                        [352.378, -14.896],
                        [356.301, -3.525],
                        [369.003, 0.931],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, 1.862],
                        [0, 3.99],
                        [-1.862, 2.307],
                        [-2.616, 1.376],
                        [-2.527, 1.153],
                        [-1.243, 1.862],
                        [0, 0],
                        [2.215, -1.284],
                        [2.305, 0],
                      ],
                      o: [
                        [-1.862, -1.862],
                        [0, -4.077],
                        [1.862, -2.305],
                        [2.614, -1.374],
                        [2.527, -1.151],
                        [0, 0],
                        [-1.951, 3.105],
                        [-2.217, 1.286],
                        [-2.839, 0],
                      ],
                      v: [
                        [369.269, -8.246],
                        [366.476, -17.024],
                        [369.269, -26.6],
                        [375.985, -32.12],
                        [383.699, -35.91],
                        [389.352, -40.432],
                        [389.352, -13.965],
                        [383.101, -7.382],
                        [376.318, -5.453],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.1, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 29,
                    s: [342.669, 386.22],
                    to: [0, -70.167],
                    ti: [0, 70.167],
                  },
                  { t: 85, s: [342.669, -34.78] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [342.669, -34.78], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.236, -0.087],
                        [3.369, 0],
                        [3.236, 0.089],
                        [2.039, 0.089],
                        [0, 0],
                        [-1.552, -0.576],
                        [-0.488, -1.55],
                        [0, -3.279],
                        [0, 0],
                        [0.486, -1.596],
                        [1.507, -0.532],
                        [3.013, -0.177],
                        [0, 0],
                        [-3.238, 0.046],
                        [-3.724, 0],
                        [-3.238, -0.044],
                        [-2.128, -0.177],
                        [0, 0],
                        [1.507, 0.532],
                        [0.532, 1.596],
                        [0, 3.192],
                        [0, 0],
                        [-0.532, 1.552],
                        [-1.509, 0.578],
                        [-2.926, 0.089],
                        [0, 0],
                      ],
                      o: [
                        [-3.238, 0.089],
                        [-3.724, 0],
                        [-3.238, -0.087],
                        [0, 0],
                        [2.926, 0.089],
                        [1.55, 0.578],
                        [0.486, 1.552],
                        [0, 0],
                        [0, 3.192],
                        [-0.488, 1.596],
                        [-1.509, 0.532],
                        [0, 0],
                        [2.039, -0.177],
                        [3.236, -0.044],
                        [3.369, 0],
                        [3.236, 0.046],
                        [0, 0],
                        [-2.926, -0.177],
                        [-1.509, -0.532],
                        [-0.532, -1.596],
                        [0, 0],
                        [0, -3.279],
                        [0.532, -1.55],
                        [1.507, -0.576],
                        [0, 0],
                        [-2.128, 0.089],
                      ],
                      v: [
                        [-315.007, 46.102],
                        [-324.915, 46.235],
                        [-335.356, 46.102],
                        [-343.269, 45.836],
                        [-343.269, 48.496],
                        [-336.553, 49.493],
                        [-333.494, 52.686],
                        [-332.762, 59.934],
                        [-332.762, 125.902],
                        [-333.494, 133.084],
                        [-336.486, 136.276],
                        [-343.269, 137.34],
                        [-343.269, 140],
                        [-335.356, 139.667],
                        [-324.915, 139.601],
                        [-315.007, 139.667],
                        [-306.96, 140],
                        [-306.96, 137.34],
                        [-313.61, 136.276],
                        [-316.669, 133.084],
                        [-317.467, 125.902],
                        [-317.467, 59.934],
                        [-316.669, 52.686],
                        [-313.61, 49.493],
                        [-306.96, 48.496],
                        [-306.96, 45.836],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "I",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "I",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.034, -2.215],
                        [2.305, -5.497],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.281, 0.133],
                        [-3.015, 0],
                        [-2.882, -0.133],
                        [-1.419, -0.087],
                        [0, 0],
                        [1.284, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [-3.148, 4.079],
                        [-4.967, 0],
                        [-1.419, -1.284],
                        [-0.445, -2.082],
                        [0, -2.571],
                        [0, 0],
                        [1.241, -1.33],
                        [3.013, 0],
                        [0, 0],
                        [-2.972, 0.133],
                        [-3.015, 0],
                        [-3.192, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.463, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [0.665, 2.66],
                        [1.596, 1.685],
                        [2.261, 0.931],
                        [3.545, 0],
                      ],
                      o: [
                        [-4.036, 2.217],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.596, -0.087],
                        [3.279, -0.133],
                        [3.103, 0],
                        [2.88, 0.133],
                        [0, 0],
                        [-2.926, 0],
                        [-1.286, -1.33],
                        [0, 0],
                        [0, -6.029],
                        [3.146, -4.077],
                        [2.837, 0],
                        [1.417, 1.286],
                        [0.443, 2.084],
                        [0, 0],
                        [0, 3.724],
                        [-1.243, 1.33],
                        [0, 0],
                        [1.507, -0.087],
                        [2.97, -0.133],
                        [3.103, 0],
                        [3.192, 0.133],
                        [0, 0],
                        [-3.458, 0],
                        [-1.463, -1.33],
                        [0, 0],
                        [0, -4.522],
                        [-0.665, -2.66],
                        [-1.509, -1.596],
                        [-2.261, -0.931],
                        [-4.522, 0],
                      ],
                      v: [
                        [-264.733, 72.835],
                        [-274.243, 84.406],
                        [-274.243, 69.909],
                        [-281.491, 71.306],
                        [-289.937, 71.771],
                        [-298.183, 71.372],
                        [-298.183, 74.165],
                        [-290.602, 77.025],
                        [-288.607, 85.869],
                        [-288.607, 127.631],
                        [-290.801, 135.212],
                        [-298.183, 137.207],
                        [-298.183, 140],
                        [-290.868, 139.667],
                        [-281.425, 139.468],
                        [-272.447, 139.667],
                        [-265.997, 140],
                        [-265.997, 137.207],
                        [-272.314, 135.212],
                        [-274.243, 127.631],
                        [-274.243, 95.578],
                        [-269.521, 80.416],
                        [-257.352, 74.298],
                        [-250.968, 76.227],
                        [-248.175, 81.281],
                        [-247.51, 88.263],
                        [-247.51, 127.631],
                        [-249.372, 135.212],
                        [-255.756, 137.207],
                        [-255.756, 140],
                        [-249.039, 139.667],
                        [-240.062, 139.468],
                        [-230.619, 139.667],
                        [-223.57, 140],
                        [-223.57, 137.207],
                        [-230.951, 135.212],
                        [-233.146, 127.631],
                        [-233.146, 91.987],
                        [-234.143, 81.214],
                        [-237.535, 74.697],
                        [-243.187, 70.906],
                        [-251.899, 69.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "n",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "n",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.192, -1.284],
                        [1.906, -2.571],
                        [0, -3.99],
                        [-1.685, -2.614],
                        [-2.706, -2.082],
                        [-3.015, -2.128],
                        [-2.706, -2.527],
                        [0, -3.901],
                        [2.394, -1.729],
                        [2.481, 0],
                        [2.438, 2.084],
                        [1.241, 3.724],
                        [0.619, 4.877],
                        [0, 0],
                        [0.087, -4.077],
                        [0.177, -2.837],
                        [0, 0],
                        [-0.711, 0.798],
                        [-0.887, -0.619],
                        [-0.931, -0.576],
                        [-0.977, -0.532],
                        [-1.862, -0.399],
                        [-2.128, 0],
                        [-3.547, 1.463],
                        [-2.174, 2.839],
                        [0, 4.079],
                        [3.369, 3.371],
                        [4.875, 3.281],
                        [2.527, 1.685],
                        [1.596, 1.951],
                        [0, 2.66],
                        [-1.951, 1.642],
                        [-2.926, 0],
                        [-2.44, -2.039],
                        [-1.286, -3.103],
                        [-0.532, -3.279],
                        [0, 0],
                        [-0.089, 3.504],
                        [-0.179, 2.483],
                        [0, 0],
                        [2.926, 2.307],
                        [2.614, 1.11],
                        [3.545, 0],
                      ],
                      o: [
                        [-3.192, 1.286],
                        [-1.908, 2.573],
                        [0, 3.637],
                        [1.683, 2.616],
                        [2.704, 2.084],
                        [4.256, 2.839],
                        [2.704, 2.527],
                        [0, 4.256],
                        [-2.394, 1.729],
                        [-4.256, 0],
                        [-2.44, -2.082],
                        [-1.243, -3.724],
                        [0, 0],
                        [0, 6.739],
                        [-0.089, 4.079],
                        [0, 0],
                        [0.353, -2.039],
                        [0.709, -0.798],
                        [0.885, 0.532],
                        [0.931, 0.578],
                        [1.596, 0.619],
                        [1.862, 0.399],
                        [4.256, 0],
                        [3.545, -1.463],
                        [2.172, -2.837],
                        [0, -5.407],
                        [-3.371, -3.369],
                        [-2.926, -1.949],
                        [-2.527, -1.683],
                        [-1.596, -1.949],
                        [0, -3.192],
                        [1.949, -1.64],
                        [3.901, 0],
                        [2.438, 2.041],
                        [1.284, 3.105],
                        [0, 0],
                        [0, -5.763],
                        [0.087, -3.502],
                        [0, 0],
                        [-0.711, 5.32],
                        [-1.153, -0.885],
                        [-2.616, -1.108],
                        [-3.637, 0],
                      ],
                      v: [
                        [-203.487, 71.439],
                        [-211.134, 77.224],
                        [-213.994, 87.066],
                        [-211.467, 96.443],
                        [-204.883, 103.492],
                        [-196.305, 109.809],
                        [-185.865, 117.855],
                        [-181.808, 127.498],
                        [-185.399, 136.475],
                        [-192.714, 139.069],
                        [-202.755, 135.943],
                        [-208.275, 127.232],
                        [-211.068, 114.331],
                        [-213.994, 114.331],
                        [-214.127, 130.557],
                        [-214.526, 140.931],
                        [-211.6, 140.931],
                        [-210.004, 136.675],
                        [-207.61, 136.409],
                        [-204.883, 138.072],
                        [-202.024, 139.734],
                        [-196.837, 141.264],
                        [-190.852, 141.862],
                        [-179.148, 139.667],
                        [-170.569, 133.217],
                        [-167.311, 122.843],
                        [-172.365, 109.676],
                        [-184.734, 99.701],
                        [-192.913, 94.248],
                        [-199.098, 88.795],
                        [-201.492, 81.879],
                        [-198.566, 74.631],
                        [-191.251, 72.17],
                        [-181.742, 75.229],
                        [-176.156, 82.943],
                        [-173.429, 92.519],
                        [-170.503, 92.519],
                        [-170.37, 78.62],
                        [-169.971, 69.643],
                        [-172.897, 69.643],
                        [-178.35, 74.165],
                        [-184.003, 71.173],
                        [-193.246, 69.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "s",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "s",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.394, -0.399],
                        [2.747, -0.087],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.711, -2.837],
                        [-1.951, -2.039],
                        [-2.041, -0.752],
                        [-3.015, 0],
                        [-2.706, 3.236],
                        [-1.064, 4.701],
                        [0, 0],
                        [1.816, -1.862],
                        [2.305, 0],
                        [1.33, 1.509],
                        [0, 3.637],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-2.394, 0.399],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.701],
                        [0.709, 2.839],
                        [1.507, 1.419],
                        [2.039, 0.752],
                        [5.231, 0],
                        [2.704, -3.236],
                        [0, 0],
                        [-1.33, 4.435],
                        [-1.818, 1.862],
                        [-2.483, 0],
                        [-1.33, -1.507],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.041, 0.887],
                      ],
                      v: [
                        [-141.244, 51.355],
                        [-148.958, 52.087],
                        [-148.958, 71.505],
                        [-161.194, 71.505],
                        [-161.194, 74.165],
                        [-148.958, 74.165],
                        [-148.958, 118.853],
                        [-147.894, 130.158],
                        [-143.904, 137.473],
                        [-138.584, 140.732],
                        [-131.003, 141.862],
                        [-119.099, 137.008],
                        [-113.447, 125.104],
                        [-115.974, 124.173],
                        [-120.695, 133.616],
                        [-126.88, 136.409],
                        [-132.599, 134.148],
                        [-134.594, 126.434],
                        [-134.594, 74.165],
                        [-116.24, 74.165],
                        [-116.24, 71.505],
                        [-134.594, 71.505],
                        [-134.594, 49.427],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "t",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.504, 1.64],
                        [-2.573, 4.345],
                        [-1.552, -2.305],
                        [-4.877, 0],
                        [-1.908, 0.885],
                        [-1.862, 1.509],
                        [0, 0],
                        [0.885, -0.532],
                        [1.064, 0],
                        [0.532, 0.977],
                        [0, 2.128],
                        [0, 0],
                        [0.709, 3.059],
                        [2.215, 2.041],
                        [2.793, 0.844],
                        [3.811, 0],
                        [3.325, -0.975],
                        [2.128, -1.417],
                        [1.197, -1.507],
                        [0, -2.394],
                        [-1.33, -1.729],
                        [-2.749, 0],
                        [-1.642, 1.419],
                        [0, 2.483],
                        [1.284, 1.552],
                        [1.949, 0.532],
                        [-2.307, 0.578],
                        [-2.041, 0],
                        [-1.729, -0.798],
                        [-1.02, -1.906],
                        [0, -3.192],
                        [0, 0],
                        [1.816, -1.33],
                        [2.793, -1.018],
                        [3.146, -1.108],
                        [2.793, -1.55],
                        [1.773, -2.394],
                        [0, -3.811],
                        [-2.616, -2.97],
                        [-5.852, 0],
                      ],
                      o: [
                        [3.502, -1.64],
                        [0.087, 3.015],
                        [1.55, 2.307],
                        [2.926, 0],
                        [1.906, -0.885],
                        [0, 0],
                        [-0.887, 0.711],
                        [-0.887, 0.532],
                        [-1.419, 0],
                        [-0.532, -0.975],
                        [0, 0],
                        [0, -4.343],
                        [-0.711, -3.059],
                        [-1.862, -1.862],
                        [-2.793, -0.842],
                        [-4.079, 0],
                        [-3.325, 0.977],
                        [-1.951, 1.33],
                        [-1.197, 1.509],
                        [0, 2.307],
                        [1.33, 1.729],
                        [2.481, 0],
                        [1.64, -1.417],
                        [0, -2.305],
                        [-1.286, -1.55],
                        [1.33, -1.683],
                        [2.305, -0.576],
                        [2.394, 0],
                        [1.729, 0.798],
                        [1.018, 1.908],
                        [0, 0],
                        [0, 2.041],
                        [-1.818, 1.33],
                        [-2.793, 1.02],
                        [-3.148, 1.11],
                        [-2.793, 1.552],
                        [-1.775, 2.394],
                        [0, 4.611],
                        [2.614, 2.972],
                        [3.99, 0],
                      ],
                      v: [
                        [-81.859, 138.471],
                        [-72.749, 129.493],
                        [-70.288, 137.473],
                        [-60.646, 140.931],
                        [-53.397, 139.601],
                        [-47.745, 136.01],
                        [-49.075, 133.749],
                        [-51.735, 135.611],
                        [-54.661, 136.409],
                        [-57.587, 134.946],
                        [-58.385, 130.291],
                        [-58.385, 93.583],
                        [-59.449, 82.477],
                        [-63.838, 74.83],
                        [-70.82, 70.773],
                        [-80.729, 69.51],
                        [-91.834, 70.973],
                        [-100.014, 74.564],
                        [-104.735, 78.82],
                        [-106.531, 84.672],
                        [-104.536, 90.723],
                        [-98.418, 93.317],
                        [-92.233, 91.189],
                        [-89.773, 85.337],
                        [-91.701, 79.551],
                        [-96.556, 76.426],
                        [-91.103, 73.035],
                        [-84.586, 72.17],
                        [-78.401, 73.367],
                        [-74.278, 77.424],
                        [-72.749, 85.071],
                        [-72.749, 94.115],
                        [-75.475, 99.169],
                        [-82.391, 102.693],
                        [-91.302, 105.885],
                        [-100.213, 109.876],
                        [-107.063, 115.794],
                        [-109.723, 125.104],
                        [-105.799, 136.475],
                        [-93.098, 140.931],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, 1.862],
                        [0, 3.99],
                        [-1.862, 2.307],
                        [-2.616, 1.376],
                        [-2.527, 1.153],
                        [-1.243, 1.862],
                        [0, 0],
                        [2.215, -1.284],
                        [2.305, 0],
                      ],
                      o: [
                        [-1.862, -1.862],
                        [0, -4.077],
                        [1.862, -2.305],
                        [2.614, -1.374],
                        [2.527, -1.151],
                        [0, 0],
                        [-1.951, 3.105],
                        [-2.217, 1.286],
                        [-2.839, 0],
                      ],
                      v: [
                        [-92.832, 131.754],
                        [-95.625, 122.976],
                        [-92.832, 113.4],
                        [-86.115, 107.881],
                        [-78.401, 104.09],
                        [-72.749, 99.568],
                        [-72.749, 126.035],
                        [-79, 132.618],
                        [-85.783, 134.547],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.409, 2.261],
                        [-2.706, 3.678],
                        [0, 4.167],
                        [4.433, 2.527],
                        [6.118, 0],
                        [0, 0],
                        [0, 2.839],
                        [-1.995, 1.463],
                        [-2.573, 1.419],
                        [0, 0],
                        [2.66, -2.837],
                        [0, -3.192],
                        [-1.642, -1.55],
                        [-2.66, -0.532],
                        [-3.105, 0],
                        [0, 0],
                        [-2.041, -1.906],
                        [0, -2.839],
                        [2.261, -2.307],
                        [3.591, -1.02],
                        [3.99, 0],
                        [3.635, 2.128],
                        [0, 3.901],
                        [-1.02, 1.773],
                        [-1.862, 0.798],
                        [0, 0],
                        [2.481, -2.217],
                        [0, -2.926],
                        [-2.527, -1.995],
                        [-4.212, -0.977],
                        [-4.967, 0],
                      ],
                      o: [
                        [5.407, -2.261],
                        [2.704, -3.68],
                        [0, -5.852],
                        [-4.435, -2.527],
                        [0, 0],
                        [-4.079, 0],
                        [0, -2.128],
                        [1.995, -1.463],
                        [0, 0],
                        [-6.297, 2.749],
                        [-2.66, 2.839],
                        [0, 2.839],
                        [1.64, 1.552],
                        [2.66, 0.532],
                        [0, 0],
                        [6.029, 0],
                        [2.039, 1.906],
                        [0, 3.635],
                        [-2.261, 2.305],
                        [-3.591, 1.018],
                        [-5.941, 0],
                        [-3.637, -2.128],
                        [0, -2.128],
                        [1.018, -1.775],
                        [0, 0],
                        [-4.522, 1.153],
                        [-2.483, 2.215],
                        [0, 3.103],
                        [2.527, 1.995],
                        [4.21, 0.975],
                        [8.157, 0],
                      ],
                      v: [
                        [1.597, 161.613],
                        [13.766, 152.702],
                        [17.823, 140.931],
                        [11.173, 128.363],
                        [-4.654, 124.572],
                        [-20.481, 124.572],
                        [-26.599, 120.316],
                        [-23.607, 114.93],
                        [-16.757, 110.607],
                        [-18.353, 109.41],
                        [-31.786, 117.789],
                        [-35.776, 126.833],
                        [-33.316, 133.417],
                        [-26.865, 136.542],
                        [-18.22, 137.34],
                        [-8.91, 137.34],
                        [3.193, 140.199],
                        [6.252, 147.315],
                        [2.86, 156.226],
                        [-5.918, 161.214],
                        [-17.289, 162.743],
                        [-31.653, 159.551],
                        [-37.106, 150.507],
                        [-35.577, 144.655],
                        [-31.254, 140.798],
                        [-32.185, 138.67],
                        [-42.692, 143.724],
                        [-46.416, 151.438],
                        [-42.626, 159.085],
                        [-32.518, 163.541],
                        [-18.752, 165.004],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.655, 3.371],
                        [0, 7.271],
                        [4.655, 3.371],
                        [6.916, 0],
                        [4.699, -3.369],
                        [0, -7.359],
                        [-4.701, -3.369],
                        [-6.829, 0],
                      ],
                      o: [
                        [4.655, -3.369],
                        [0, -7.359],
                        [-4.655, -3.369],
                        [-6.829, 0],
                        [-4.701, 3.371],
                        [0, 7.271],
                        [4.699, 3.371],
                        [6.916, 0],
                      ],
                      v: [
                        [-0.598, 106.617],
                        [6.385, 90.657],
                        [-0.598, 74.564],
                        [-17.954, 69.51],
                        [-35.244, 74.564],
                        [-42.293, 90.657],
                        [-35.244, 106.617],
                        [-17.954, 111.671],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.773, 2.66],
                        [0, 7.095],
                        [-1.775, 2.706],
                        [-2.926, 0],
                        [-1.685, -2.704],
                        [0, -7.093],
                        [1.683, -2.66],
                        [3.103, 0],
                      ],
                      o: [
                        [-1.775, -2.66],
                        [0, -7.093],
                        [1.773, -2.704],
                        [3.103, 0],
                        [1.683, 2.706],
                        [0, 7.095],
                        [-1.685, 2.66],
                        [-2.926, 0],
                      ],
                      v: [
                        [-25.003, 105.287],
                        [-27.663, 90.657],
                        [-25.003, 75.96],
                        [-17.954, 71.904],
                        [-10.772, 75.96],
                        [-8.245, 90.657],
                        [-10.772, 105.287],
                        [-17.954, 109.277],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.642, 1.552],
                        [-3.547, 1.419],
                        [0, 0],
                        [-0.179, -2.082],
                        [-1.197, -0.975],
                        [-1.419, 0],
                        [-1.463, 1.153],
                        [0, 2.749],
                        [1.463, 1.376],
                        [2.481, 0],
                        [3.013, -2.305],
                        [1.241, -3.103],
                        [0, 0],
                      ],
                      o: [
                        [1.64, -1.55],
                        [0, 0],
                        [-1.509, 3.281],
                        [0.177, 2.084],
                        [1.197, 0.977],
                        [1.596, 0],
                        [1.463, -1.151],
                        [0, -2.481],
                        [-1.463, -1.374],
                        [-3.281, 0],
                        [-3.015, 2.307],
                        [0, 0],
                        [1.33, -2.571],
                      ],
                      v: [
                        [6.185, 74.497],
                        [13.966, 70.042],
                        [12.503, 69.51],
                        [10.508, 77.557],
                        [12.569, 82.145],
                        [16.493, 83.608],
                        [21.081, 81.879],
                        [23.276, 76.027],
                        [21.081, 70.242],
                        [15.163, 68.18],
                        [5.72, 71.638],
                        [-0.664, 79.751],
                        [1.73, 80.682],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "g",
              np: 7,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.481, -1.55],
                        [1.773, -2.394],
                        [1.064, -2.571],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.192, 0.133],
                        [-3.192, 0],
                        [-3.77, -0.133],
                        [-2.041, -0.087],
                        [0, 0],
                        [2.394, 1.463],
                        [0, 4.345],
                        [0, 0],
                        [-1.419, 3.458],
                        [-2.44, 2.44],
                        [-3.192, 0.179],
                        [-0.887, -0.709],
                        [1.018, -1.374],
                        [0, -1.949],
                        [-1.552, -1.33],
                        [-2.217, 0],
                        [-1.552, 1.818],
                        [0, 2.573],
                        [0.931, 1.642],
                        [1.816, 1.064],
                        [2.571, 0],
                      ],
                      o: [
                        [-2.483, 1.552],
                        [-1.775, 2.394],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.683, -0.087],
                        [3.192, -0.133],
                        [4.167, 0],
                        [3.768, 0.133],
                        [0, 0],
                        [-4.256, 0],
                        [-2.394, -1.463],
                        [0, 0],
                        [0, -2.926],
                        [1.417, -3.458],
                        [2.438, -2.438],
                        [1.241, -0.087],
                        [-1.33, 0.798],
                        [-1.02, 1.376],
                        [0, 2.307],
                        [1.55, 1.33],
                        [2.571, 0],
                        [1.55, -1.816],
                        [0, -2.039],
                        [-0.931, -1.64],
                        [-1.818, -1.064],
                        [-3.015, 0],
                      ],
                      v: [
                        [62.111, 71.837],
                        [55.727, 77.756],
                        [51.471, 85.204],
                        [51.471, 69.909],
                        [44.223, 71.306],
                        [35.777, 71.771],
                        [27.531, 71.372],
                        [27.531, 74.165],
                        [35.112, 77.025],
                        [37.107, 85.869],
                        [37.107, 127.631],
                        [34.913, 135.212],
                        [27.531, 137.207],
                        [27.531, 140],
                        [34.846, 139.667],
                        [44.422, 139.468],
                        [56.326, 139.667],
                        [65.037, 140],
                        [65.037, 137.207],
                        [55.062, 135.012],
                        [51.471, 126.301],
                        [51.471, 95.711],
                        [53.599, 86.135],
                        [59.385, 77.29],
                        [67.83, 73.367],
                        [71.022, 74.298],
                        [67.498, 77.557],
                        [65.968, 82.544],
                        [68.296, 87.997],
                        [73.948, 89.992],
                        [80.133, 87.266],
                        [82.46, 80.682],
                        [81.064, 75.163],
                        [76.941, 71.106],
                        [70.357, 69.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "r",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "r",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.504, 1.64],
                        [-2.573, 4.345],
                        [-1.552, -2.305],
                        [-4.877, 0],
                        [-1.908, 0.885],
                        [-1.862, 1.509],
                        [0, 0],
                        [0.885, -0.532],
                        [1.064, 0],
                        [0.532, 0.977],
                        [0, 2.128],
                        [0, 0],
                        [0.709, 3.059],
                        [2.215, 2.041],
                        [2.793, 0.844],
                        [3.811, 0],
                        [3.325, -0.975],
                        [2.128, -1.417],
                        [1.197, -1.507],
                        [0, -2.394],
                        [-1.33, -1.729],
                        [-2.749, 0],
                        [-1.642, 1.419],
                        [0, 2.483],
                        [1.284, 1.552],
                        [1.949, 0.532],
                        [-2.307, 0.578],
                        [-2.041, 0],
                        [-1.729, -0.798],
                        [-1.02, -1.906],
                        [0, -3.192],
                        [0, 0],
                        [1.816, -1.33],
                        [2.793, -1.018],
                        [3.146, -1.108],
                        [2.793, -1.55],
                        [1.773, -2.394],
                        [0, -3.811],
                        [-2.616, -2.97],
                        [-5.852, 0],
                      ],
                      o: [
                        [3.502, -1.64],
                        [0.087, 3.015],
                        [1.55, 2.307],
                        [2.926, 0],
                        [1.906, -0.885],
                        [0, 0],
                        [-0.887, 0.711],
                        [-0.887, 0.532],
                        [-1.419, 0],
                        [-0.532, -0.975],
                        [0, 0],
                        [0, -4.343],
                        [-0.711, -3.059],
                        [-1.862, -1.862],
                        [-2.793, -0.842],
                        [-4.079, 0],
                        [-3.325, 0.977],
                        [-1.951, 1.33],
                        [-1.197, 1.509],
                        [0, 2.307],
                        [1.33, 1.729],
                        [2.481, 0],
                        [1.64, -1.417],
                        [0, -2.305],
                        [-1.286, -1.55],
                        [1.33, -1.683],
                        [2.305, -0.576],
                        [2.394, 0],
                        [1.729, 0.798],
                        [1.018, 1.908],
                        [0, 0],
                        [0, 2.041],
                        [-1.818, 1.33],
                        [-2.793, 1.02],
                        [-3.148, 1.11],
                        [-2.793, 1.552],
                        [-1.775, 2.394],
                        [0, 4.611],
                        [2.614, 2.972],
                        [3.99, 0],
                      ],
                      v: [
                        [116.707, 138.471],
                        [125.818, 129.493],
                        [128.278, 137.473],
                        [137.921, 140.931],
                        [145.169, 139.601],
                        [150.822, 136.01],
                        [149.492, 133.749],
                        [146.832, 135.611],
                        [143.906, 136.409],
                        [140.98, 134.946],
                        [140.182, 130.291],
                        [140.182, 93.583],
                        [139.118, 82.477],
                        [134.729, 74.83],
                        [127.746, 70.773],
                        [117.838, 69.51],
                        [106.732, 70.973],
                        [98.553, 74.564],
                        [93.831, 78.82],
                        [92.036, 84.672],
                        [94.031, 90.723],
                        [100.149, 93.317],
                        [106.333, 91.189],
                        [108.794, 85.337],
                        [106.865, 79.551],
                        [102.011, 76.426],
                        [107.464, 73.035],
                        [113.981, 72.17],
                        [120.165, 73.367],
                        [124.288, 77.424],
                        [125.818, 85.071],
                        [125.818, 94.115],
                        [123.091, 99.169],
                        [116.175, 102.693],
                        [107.264, 105.885],
                        [98.353, 109.876],
                        [91.504, 115.794],
                        [88.844, 125.104],
                        [92.767, 136.475],
                        [105.469, 140.931],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.862, 1.862],
                        [0, 3.99],
                        [-1.862, 2.307],
                        [-2.616, 1.376],
                        [-2.527, 1.153],
                        [-1.243, 1.862],
                        [0, 0],
                        [2.215, -1.284],
                        [2.305, 0],
                      ],
                      o: [
                        [-1.862, -1.862],
                        [0, -4.077],
                        [1.862, -2.305],
                        [2.614, -1.374],
                        [2.527, -1.151],
                        [0, 0],
                        [-1.951, 3.105],
                        [-2.217, 1.286],
                        [-2.839, 0],
                      ],
                      v: [
                        [105.735, 131.754],
                        [102.942, 122.976],
                        [105.735, 113.4],
                        [112.451, 107.881],
                        [120.165, 104.09],
                        [125.818, 99.568],
                        [125.818, 126.035],
                        [119.567, 132.618],
                        [112.784, 134.547],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "a 2",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.857, -2.305],
                        [2.305, -5.32],
                        [0, 0],
                        [2.614, -0.31],
                        [3.013, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [1.463, -1.33],
                        [3.458, 0],
                        [0, 0],
                        [-3.281, 0.133],
                        [-3.015, 0],
                        [-2.839, -0.133],
                        [-1.419, -0.087],
                        [0, 0],
                        [1.241, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [-1.463, 3.238],
                        [-2.44, 1.862],
                        [-3.105, 0],
                        [-1.286, -1.284],
                        [-0.355, -2.082],
                        [0, -2.571],
                        [0, 0],
                        [1.197, -1.33],
                        [2.926, 0],
                        [0, 0],
                        [-2.882, 0.133],
                        [-3.015, 0],
                        [-3.148, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.417, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [0.619, 2.793],
                        [1.596, 1.685],
                        [2.305, 0.931],
                        [3.369, 0],
                      ],
                      o: [
                        [-3.857, 2.307],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.616, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 3.724],
                        [-1.463, 1.33],
                        [0, 0],
                        [1.596, -0.087],
                        [3.279, -0.133],
                        [3.013, 0],
                        [2.837, 0.133],
                        [0, 0],
                        [-2.839, 0],
                        [-1.243, -1.33],
                        [0, 0],
                        [0, -3.99],
                        [1.463, -3.236],
                        [2.438, -1.862],
                        [2.747, 0],
                        [1.284, 1.286],
                        [0.353, 2.084],
                        [0, 0],
                        [0, 3.724],
                        [-1.197, 1.33],
                        [0, 0],
                        [1.507, -0.087],
                        [2.88, -0.133],
                        [3.013, 0],
                        [3.146, 0.133],
                        [0, 0],
                        [-3.371, 0],
                        [-1.419, -1.33],
                        [0, 0],
                        [0, -4.256],
                        [-0.621, -2.793],
                        [-1.419, -1.596],
                        [-2.307, -0.931],
                        [-4.256, 0],
                      ],
                      v: [
                        [187.994, 72.968],
                        [178.75, 84.406],
                        [178.75, 69.909],
                        [171.502, 71.306],
                        [163.056, 71.771],
                        [154.81, 71.372],
                        [154.81, 74.165],
                        [162.391, 77.025],
                        [164.386, 85.869],
                        [164.386, 127.631],
                        [162.192, 135.212],
                        [154.81, 137.207],
                        [154.81, 140],
                        [162.125, 139.667],
                        [171.568, 139.468],
                        [180.346, 139.667],
                        [186.73, 140],
                        [186.73, 137.207],
                        [180.612, 135.212],
                        [178.75, 127.631],
                        [178.75, 95.578],
                        [180.945, 84.738],
                        [186.797, 77.091],
                        [195.109, 74.298],
                        [201.161, 76.227],
                        [203.621, 81.281],
                        [204.153, 88.263],
                        [204.153, 127.631],
                        [202.358, 135.212],
                        [196.173, 137.207],
                        [196.173, 140],
                        [202.757, 139.667],
                        [211.601, 139.468],
                        [220.845, 139.667],
                        [227.827, 140],
                        [227.827, 137.207],
                        [220.645, 135.212],
                        [218.517, 127.631],
                        [218.517, 91.987],
                        [217.586, 81.413],
                        [214.261, 74.697],
                        [208.675, 70.906],
                        [200.163, 69.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "m",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.034, -2.128],
                        [2.394, -5.852],
                        [0, 0],
                        [-3.148, 4.123],
                        [-4.611, 0],
                        [-1.33, -1.284],
                        [-0.355, -2.082],
                        [0, -2.571],
                        [0, 0],
                        [1.197, -1.33],
                        [2.926, 0],
                        [0, 0],
                        [-2.882, 0.133],
                        [-3.015, 0],
                        [-3.192, -0.133],
                        [-1.509, -0.087],
                        [0, 0],
                        [1.463, 1.33],
                        [0, 3.724],
                        [0, 0],
                        [0.619, 2.793],
                        [1.596, 1.685],
                        [2.305, 0.931],
                        [3.369, 0],
                      ],
                      o: [
                        [-4.036, 2.128],
                        [0, 0],
                        [0, -6.029],
                        [3.146, -4.123],
                        [2.837, 0],
                        [1.33, 1.286],
                        [0.353, 2.084],
                        [0, 0],
                        [0, 3.724],
                        [-1.197, 1.33],
                        [0, 0],
                        [1.507, -0.087],
                        [2.88, -0.133],
                        [3.103, 0],
                        [3.192, 0.133],
                        [0, 0],
                        [-3.458, 0],
                        [-1.463, -1.33],
                        [0, 0],
                        [0, -4.256],
                        [-0.621, -2.793],
                        [-1.419, -1.596],
                        [-2.307, -0.931],
                        [-4.345, 0],
                      ],
                      v: [
                        [227.628, 72.702],
                        [217.985, 84.672],
                        [218.517, 95.711],
                        [223.239, 80.482],
                        [234.876, 74.298],
                        [241.127, 76.227],
                        [243.654, 81.281],
                        [244.186, 88.263],
                        [244.186, 127.631],
                        [242.391, 135.212],
                        [236.206, 137.207],
                        [236.206, 140],
                        [242.79, 139.667],
                        [251.634, 139.468],
                        [261.077, 139.667],
                        [268.126, 140],
                        [268.126, 137.207],
                        [260.745, 135.212],
                        [258.55, 127.631],
                        [258.55, 91.987],
                        [257.619, 81.413],
                        [254.294, 74.697],
                        [248.708, 70.906],
                        [240.196, 69.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "m",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "m",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.66, -0.31],
                        [2.926, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [3.103, -4.077],
                        [4.609, 0],
                        [1.33, 1.243],
                        [0.353, 2.128],
                        [0, 2.573],
                        [0, 0],
                        [2.66, -0.31],
                        [2.926, 0],
                        [2.837, 0.266],
                        [0, 0],
                        [-1.33, -1.906],
                        [0, -3.99],
                        [0, 0],
                        [-0.621, -2.793],
                        [-1.596, -1.773],
                        [-2.307, -0.931],
                        [-3.281, 0],
                        [-3.946, 2.261],
                        [-2.217, 5.32],
                        [0, 0],
                        [-2.66, 0.266],
                        [-2.926, 0],
                        [-2.749, -0.266],
                        [0, 0],
                        [1.374, 1.908],
                        [0, 3.99],
                        [0, 0],
                      ],
                      o: [
                        [-2.66, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 5.941],
                        [-3.105, 4.079],
                        [-2.926, 0],
                        [-1.33, -1.241],
                        [-0.355, -2.128],
                        [0, 0],
                        [-2.217, 0.621],
                        [-2.66, 0.312],
                        [-2.66, 0],
                        [0, 0],
                        [3.724, 0],
                        [1.33, 1.908],
                        [0, 0],
                        [0, 4.256],
                        [0.619, 2.793],
                        [1.507, 1.509],
                        [2.305, 0.931],
                        [4.343, 0],
                        [3.944, -2.261],
                        [0, 0],
                        [2.215, -0.711],
                        [2.66, -0.266],
                        [2.747, 0],
                        [0, 0],
                        [-3.637, 0],
                        [-1.376, -1.906],
                        [0, 0],
                        [-2.217, 0.621],
                      ],
                      v: [
                        [328.774, 71.306],
                        [320.395, 71.771],
                        [312.149, 71.372],
                        [312.149, 74.165],
                        [319.73, 77.025],
                        [321.725, 85.869],
                        [321.725, 115.927],
                        [317.07, 130.956],
                        [305.499, 137.074],
                        [299.115, 135.212],
                        [296.588, 130.158],
                        [296.056, 123.109],
                        [296.056, 69.909],
                        [288.741, 71.306],
                        [280.362, 71.771],
                        [272.116, 71.372],
                        [272.116, 74.165],
                        [279.697, 77.025],
                        [281.692, 85.869],
                        [281.692, 119.385],
                        [282.623, 129.958],
                        [285.948, 136.808],
                        [291.667, 140.465],
                        [300.046, 141.862],
                        [312.481, 138.471],
                        [321.725, 127.099],
                        [321.725, 141.596],
                        [329.04, 140.133],
                        [337.419, 139.734],
                        [345.665, 140.133],
                        [345.665, 137.34],
                        [338.15, 134.48],
                        [336.089, 125.636],
                        [336.089, 69.909],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "u",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "u",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.1, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 32,
                    s: [1.198, 549.42],
                    to: [0, -74],
                    ti: [0, 74],
                  },
                  { t: 96, s: [1.198, 105.42] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [1.198, 105.42], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 10,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 129,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 1,
      nm: "Dark Gray Solid 1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 55.001,
              s: [0],
            },
            { t: 80, s: [57] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [375, 378.673, 0], ix: 2 },
        a: { a: 0, k: [576, 406, 0], ix: 1 },
        s: { a: 0, k: [95.88, 95.88, 100], ix: 6 },
      },
      ao: 0,
      sw: 1152,
      sh: 812,
      sc: "#161616",
      ip: 0,
      op: 129,
      st: 0,
      bm: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "ZD instagram (1 of 1).jpg",
      cl: "jpg",
      parent: 1,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [60, 1693.964, 0],
              to: [0, -612.179, 0],
              ti: [0, 646.448, 0],
            },
            {
              i: { x: 0.651, y: 1 },
              o: { x: 0.318, y: 0.657 },
              t: 128,
              s: [60, -1996.084, 0],
              to: [0, -6.146, 0],
              ti: [0, 5.821, 0],
            },
            { t: 144, s: [60, -2014.036, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [500, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.03, 0.03, 0.03], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 0,
              s: [148, 148, 100],
            },
            { t: 129, s: [116, 116, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 129,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
export default instagramAnimacija750
